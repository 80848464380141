import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button, Image, Link, Icon, List, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdHelp } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				All Services | Taskmasters
			</title>
			<meta name={"description"} content={"All services provided by Taskmasters"} />
			<meta property={"og:title"} content={"All Services | Taskmasters"} />
			<meta property={"og:description"} content={"All services provided by Taskmasters"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/ogimage.png?v=2024-02-14T13:32:07.319Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:47.550Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:25:03.164Z"} />
		</Helmet>
		<Components.Header />
		<Section quarkly-title="Hero header 1 /1/" padding="112px 0 112px 0" sm-padding="64px 0 64px 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				display="flex"
				justify-content="space-between"
				sm-flex-direction="column"
				grid-gap="80px"
			/>
			<Box
				display="flex"
				flex-direction="column"
				justify-content="center"
				grid-gap="24px"
				width="100%"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				lg-display="grid"
				align-items="center"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="--headline1"
					as="h1"
					color="--primary"
					sm-font="--headline2"
					text-align="center"
				>
					Our Services
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					max-width="800px"
					text-align="center"
				>
					The quality of our services is confirmed by our experience, the number of loyal customers, and international certificates
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-lightD1" sm-padding="60px 0 60px 0" quarkly-title="Schedule-8">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(6, 1fr)"
				grid-gap="30px 30px"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
				grid-column="1/4"
				md-grid-column="1/7"
				sm-padding="24px 24px 24px 24px"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Company Incorporation
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Incorporating a company in Cyprus can provide numerous benefits for businesses seeking tax optimization, international expansion, and a favorable regulatory environment.
				</Text>
				<Box padding="16px 0px 0px 0px">
					<Box
						display="flex"
						grid-gap="16px"
						flex-direction="row"
						align-items="flex-start"
						sm-flex-direction="column"
					>
						<Button
							type="link"
							text-decoration-line="initial"
							href="mailto:olga.yatsenko@taskmasters.ltd?Company Incorporation"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="white"
							text-align="center"
						>
							Order this service
						</Button>
						<Button
							type="link"
							text-decoration-line="initial"
							href="/services/company-incorporation"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="--primary"
							border-width="0px"
							background="rgba(22, 163, 74, 0.1)"
							text-align="center"
						>
							Read more about
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
				grid-column="4/7"
				md-grid-column="1/7"
				sm-padding="24px 24px 24px 24px"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Accounting Services
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Doing accounting in Cyprus can help businesses maintain financial transparency, optimize tax benefits, manage risks, and comply with legal and regulatory requirements, ultimately contributing to their long-term success and sustainability.
				</Text>
				<Box padding="16px 0px 0px 0px">
					<Box
						display="flex"
						grid-gap="16px"
						flex-direction="row"
						align-items="flex-start"
						sm-flex-direction="column"
					>
						<Button
							type="link"
							text-decoration-line="initial"
							href="mailto:olga.yatsenko@taskmasters.ltd?Accounting Services"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="white"
							text-align="center"
						>
							Order this service
						</Button>
						<Button
							type="link"
							text-decoration-line="initial"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="--primary"
							border-width="0px"
							background="rgba(22, 163, 74, 0.1)"
							text-align="center"
							href="/services/accounting-services"
						>
							Read more about
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
				grid-column="1/4"
				md-grid-column="1/7"
				sm-padding="24px 24px 24px 24px"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Registration
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Businesses in Cyprus must register with the social insurance and tax departments to comply with legal requirements, ensure employee benefits, and maintain credibility. Registration also allows businesses to fulfill tax obligations, access government services, and demonstrate legitimacy to stakeholders.
				</Text>
				<Box padding="16px 0px 0px 0px">
					<Box
						display="flex"
						grid-gap="16px"
						flex-direction="row"
						align-items="flex-start"
						sm-flex-direction="column"
					>
						<Button
							type="link"
							text-decoration-line="initial"
							href="mailto:olga.yatsenko@taskmasters.ltd?Registration"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="white"
							text-align="center"
						>
							Order this service
						</Button>
						<Button
							type="link"
							text-decoration-line="initial"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="--primary"
							border-width="0px"
							background="rgba(22, 163, 74, 0.1)"
							text-align="center"
							href="/services/registration"
						>
							Read more about
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
				grid-column="4/7"
				md-grid-column="1/7"
				sm-padding="24px 24px 24px 24px"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Deregistration
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Deregistering from the social insurance and tax departments in Cyprus is necessary when ceasing operations or no longer having employees to avoid ongoing obligations and potential penalties, ensuring legal compliance and preventing unnecessary costs associated with social insurance contributions and tax filings.
				</Text>
				<Box padding="16px 0px 0px 0px">
					<Box
						display="flex"
						grid-gap="16px"
						flex-direction="row"
						align-items="flex-start"
						sm-flex-direction="column"
					>
						<Button
							type="link"
							text-decoration-line="initial"
							href="mailto:olga.yatsenko@taskmasters.ltd?Deregistration"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="white"
							text-align="center"
						>
							Order this service
						</Button>
						<Button
							type="link"
							text-decoration-line="initial"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="--primary"
							border-width="0px"
							background="rgba(22, 163, 74, 0.1)"
							text-align="center"
							href="/services/deregistration"
						>
							Read more about
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
				grid-column="1/4"
				md-grid-column="1/7"
				sm-padding="24px 24px 24px 24px"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Tax Consulting and Tax Compliance
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Tax compliance in Cyprus is essential for legal, financial, reputational, and ethical reasons. By fulfilling your tax obligations, you protect your business's interests, maintain its reputation, and contribute to the overall well-being of the economy and society.
				</Text>
				<Box padding="16px 0px 0px 0px">
					<Box
						display="flex"
						grid-gap="16px"
						flex-direction="row"
						align-items="flex-start"
						sm-flex-direction="column"
					>
						<Button
							type="link"
							text-decoration-line="initial"
							href="mailto:olga.yatsenko@taskmasters.ltd?Tax Consulting"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="white"
							text-align="center"
						>
							Order this service
						</Button>
						<Button
							type="link"
							text-decoration-line="initial"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="--primary"
							border-width="0px"
							background="rgba(22, 163, 74, 0.1)"
							text-align="center"
							href="/services/tax-consulting-and-tax-compliance"
						>
							Read more about
						</Button>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
				grid-column="4/7"
				md-grid-column="1/7"
				sm-padding="24px 24px 24px 24px"
			>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Obtaining Tax Certificates
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Obtaining tax certificates in Cyprus is essential for demonstrating tax compliance, fulfilling legal requirements, facilitating business transactions, and avoiding penalties or delays.
				</Text>
				<Box padding="16px 0px 0px 0px">
					<Box
						display="flex"
						grid-gap="16px"
						flex-direction="row"
						align-items="flex-start"
						sm-flex-direction="column"
					>
						<Button
							type="link"
							text-decoration-line="initial"
							href="mailto:olga.yatsenko@taskmasters.ltd?Tax Compliance"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="white"
							text-align="center"
						>
							Order this service
						</Button>
						<Button
							type="link"
							text-decoration-line="initial"
							font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
							border-radius="12px"
							color="--primary"
							border-width="0px"
							background="rgba(22, 163, 74, 0.1)"
							text-align="center"
							href="/services/obtaining-tax-certificates"
						>
							Read more about
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10" background="white">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="--primary"
				font="--base"
				border-color="#dca654"
				text-transform="uppercase"
			>
				Proof of our expertise
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				grid-gap="24px"
			>
				<Box min-width="100px" min-height="100px" width="50%" lg-width="100%">
					<Text margin="0 0px 0px 0px" font="--headline2" color="--darkL2" md-font="--headline3">
						Explore our certifications showcasing our expertise in business administration and accounting services for companies
					</Text>
				</Box>
				<Box
					display="flex"
					width="50%"
					lg-width="100%"
					lg-margin="20px 0px 0px 0px"
					md-flex-direction="column"
					md-display="flex"
					md-grid-gap="40px"
					sm-display="flex"
					sm-grid-gap="36px"
				>
					<Box
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						display="flex"
						flex-direction="column"
						grid-gap="16px"
						align-items="flex-start"
						width="100%"
						lg-width="45%"
						md-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12:14:01.885Z"
							display="block"
							width="200px"
							padding="16px 16px 16px 16px"
							background="white"
							height="94px"
							srcSet="https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-cysec-logo.jpeg?v=2024-01-05T12%3A14%3A01.885Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1279px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							font="--lead"
							text-align="center"
							href="https://www.accaglobal.com/gb/en/member/find-an-accountant/directory-of-member/results.html?isocountry=RU&FirstName=Olga&Surname=Yatsenko&Location=&inputcountrysuspended=&orgid=ACCA&orby=FNA&ipp=5&pn=1&hid=&requestcount=1"
							target="_blank"
							rel="nofollow"
							color="--darkL2"
							padding="0px 0px 0px 16px"
						>
							Certified by{" "}
							<Link href="https://www.cysec.gov.cy/en-GB/home/" rel="nofollow" target="_blank" color="--primary">
								CySec
							</Link>
						</Text>
					</Box>
					<Box
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						sm-margin="0px 0 30px 0px"
						display="flex"
						flex-direction="column"
						grid-gap="16px"
						align-items="flex-start"
						width="100%"
						lg-width="45%"
						md-width="100%"
					>
						<Image
							src="https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-acca-full-logo.svg?v=2024-01-05T12:33:47.791Z"
							display="block"
							flex="0 0 auto"
							width="200px"
							padding="16px 16px 16px 16px"
							background="white"
							height="94px"
						/>
						<Link
							margin="0px 0px 0px 0px"
							font="--lead"
							color="--primary"
							text-align="center"
							href="https://www.accaglobal.com/gb/en/member/find-an-accountant/directory-of-member/results.html?isocountry=RU&FirstName=Olga&Surname=Yatsenko&Location=&inputcountrysuspended=&orgid=ACCA&orby=FNA&ipp=5&pn=1&hid=&requestcount=1"
							target="_blank"
							rel="nofollow"
							padding="0px 0px 0px 16px"
						>
							ACCA member
						</Link>
						<Text
							margin="0px 0px 0px 0px"
							font="--base"
							color="--darkL2"
							text-align="left"
							padding="0px 0px 0px 16px"
							md-max-width="540px"
						>
							The Association of Chartered Certified Accountants are a globally recognised professional accountancy body providing qualifications and advancing standards in accountancy worldwide.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Q&A
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0" sm-font="--lead">
					What are the rates?
				</Text>
				<Text
					as="p"
					font="--lead"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					sm-font="--base"
				>
					Possible cooperation options:
				</Text>
				<List margin="0px 0px 8px 0px" padding="0px 0px 0px 20px" list-style-type="decimal" as="ol">
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Fixed payment of €1,000 per month.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Hourly rate of €50
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						as="p"
						color="--darkL2"
						font="--lead"
						sm-font="--base"
					>
						Hourly rate of €30 plus 2% of annual revenue.
					</Text>
				</List>
				<Text
					as="p"
					font="--lead"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					sm-font="--base"
				>
					Rates are exclusive of VAT.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				max-width="800px"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--primary"
					category="md"
					icon={MdHelp}
				/>
				<Text as="h3" font="--headline3" margin="10px 0" sm-font="--lead">
					What does it take to create a "substance" company?
				</Text>
				<Components.ShowMore once>
					<Override slot="Content">
						<Text
							as="p"
							font="--lead"
							margin="0px 0px 0px 0px"
							color="--darkL2"
							sm-font="--base"
						>
							To set up a "substance" company in Cyprus, i.e. a company that demonstrates the existence of real business and economic activity on the island, you will need the following:
						</Text>
					</Override>
					<Override
						slot="Button"
						color="white"
						font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						border-radius="12px"
						margin="18px 0px 0px 0px"
					/>
					<Override slot="MoreContent">
						<List margin="8px 0px 8px 0px" padding="0px 0px 0px 20px" list-style-type="decimal" as="ol">
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Physical presence
								</Strong>
								: Your company must have a physical address in Cyprus which can be used as a registered office.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Local directors and employees
								</Strong>
								: It is understood that the company will have at least one local director and possibly several local employees depending on the volume of activity.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Functional Business
								</Strong>
								: The company must be actively engaged in business in Cyprus, including entering into contracts, performing services or manufacturing goods.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Accounting and tax accounting
								</Strong>
								: You will need to maintain accounting and tax records in accordance with Cyprus law, including filing tax returns and paying taxes on time.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Opening a bank account
								</Strong>
								: The company must have a bank account in Cyprus to carry out financial transactions and receive payments.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Documentation of activities
								</Strong>
								: Documentation of the company's activities should be maintained, including contracts, invoices, checks, reports and other financial records to prove its actual activities.
							</Text>
							<Text
								margin="0px 0px 0px 0px"
								as="p"
								font="--lead"
								color="--darkL2"
								sm-font="--base"
							>
								<Strong>
									Compliance with all laws and regulations
								</Strong>
								: The company must comply with all Cyprus laws and regulations, including registration and business requirements.
							</Text>
						</List>
						<Text
							as="p"
							font="--lead"
							margin="0px 0px 0px 0px"
							color="--darkL2"
							sm-font="--base"
						>
							These steps will help your company to demonstrate that it has a real business and economic activity in Cyprus, which is essential for establishing a business in Cyprus.
						</Text>
					</Override>
				</Components.ShowMore>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"657c2c8a8d4a0c0020281f8b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});